import { Injectable } from "@angular/core";
import { BaseApi, BaseService, EmptySerializer } from 'src/app/main';
import { SaleEventInfo, SaleEventType } from "../enums";
import { SaleEvent } from "../models";

@Injectable({
    providedIn: 'root'
})
export class SaleEventService {

    private api: BaseApi;

    constructor(
        base: BaseService
    ) {
        this.api = base.setApi('gateway', 'sale/SaleEvent');
    }

    getSaleEventFlowIdentifier = (): Promise<string> => {
        return this.api.post('GetSaleEventFlowIdentifier', null, new EmptySerializer()).toPromise();
    }

    saveSaleEventData = (saleFlowIdentifier: string, saleEventType: SaleEventType, saleEventInfo: SaleEventInfo, saleEventData: any, idCustomer?: number, eventInfoIndex?: number): Promise<SaleEvent> => {
        const data = 
            <SaleEvent>{
                flowIdentifier: saleFlowIdentifier,
                idCustomer: idCustomer,
                eventType: saleEventType,
                eventInfo: saleEventInfo,
                eventInfoIndex: eventInfoIndex,
                eventData: saleEventData            
        }

        return this.api.post('SaveSaleEvent', data, new EmptySerializer()).toPromise();
    }

    saveSaleEvent = (saleEvent: SaleEvent): Promise<SaleEvent> => {
        const data = saleEvent;        

        return this.api.post('SaveSaleEvent', data, new EmptySerializer()).toPromise();
    }
}