import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CieloSaleRequest, PayPalFinalizeSaleRequestModel } from 'src/app/core';
import { MercadoPagoPixSaleRequest, PaymeeBankTransferSaleRequest, PaymeePixSaleRequest, SaleRequestEvent, SaleUpgradeOfferEvent, TripChange, TripSelectedEvent } from '../models';
import { MakeFullSaleRequestEvent } from '../models/booking-process/make-full-sale-request-event';

@Injectable()
export class InternalAnnouncerService {
    private tripSelectedAnnouncedSource = new Subject<TripSelectedEvent>();
    private outletSelectedAnnouncedSource = new Subject<TripSelectedEvent>();
    private checkoutOnlinePaymeePixSaleRequestAnnouncedSource = new Subject<PaymeePixSaleRequest>();
    private payPalSaleRequestAnnouncedSource = new Subject<SaleRequestEvent>();
    private checkoutOnlinePayPalSaleRequestAnnouncedSource = new Subject<SaleRequestEvent>();
    private payPalFinalizeSaleRequestAnnouncedSource = new Subject<PayPalFinalizeSaleRequestModel>();
    private tripSearchDataChangedAnnouncedSource = new Subject<any>();
    private tripSelectionChangedAnnouncedSource = new Subject<TripChange>();
    private clearTempSeatsAnnouncedSource = new Subject<number>();
    private closeAllExceptSeatSource = new Subject<number>();
    private bookingUpdatedAnnouncedSource = new Subject<any>();
    private preSaleUpgradeEventAnnouncedSource = new Subject<number>();
    private saleUpgradeOfferEventAnnouncedSource = new Subject<SaleUpgradeOfferEvent>();
    private saleUpgradeRequestAnnouncedSource = new Subject<TripChange>();
    private voucherUpdatedAnnouncedSource = new Subject<any>();
    private mbWaySaleRequestAnnouncedSource = new Subject<SaleRequestEvent>();
    private checkoutOnlineMbWaySaleRequestAnnouncedSource = new Subject<SaleRequestEvent>();
    private extrasSelectedAnnouncedSource = new Subject<any>();
    private cieloSaleRequestAnnouncedSource = new Subject<CieloSaleRequest>();
    private paymeePixSaleRequestAnnouncedSource = new Subject<PaymeePixSaleRequest>();

    private makeFullSaleRequestAnnoucedSource =  new Subject<MakeFullSaleRequestEvent>();

    private logoffAnnouncedSource = new Subject<void>();
    private optionalInsuranceAnnouncedSource = new Subject<boolean>();

    tripSelectedAnnounced$ = this.tripSelectedAnnouncedSource.asObservable();
    outletSelectedAnnounced$ = this.outletSelectedAnnouncedSource.asObservable();
    checkoutOnlinePaymeePixSaleRequestAnnounced$ = this.checkoutOnlinePaymeePixSaleRequestAnnouncedSource.asObservable();
    tripSearchDataChangedAnnounced$ = this.tripSearchDataChangedAnnouncedSource.asObservable();
    tripSelectionChangedAnnounced$ = this.tripSelectionChangedAnnouncedSource.asObservable();
    clearTempSeatsAnnounced$ = this.clearTempSeatsAnnouncedSource.asObservable();
    closeAllExceptSeat$ = this.closeAllExceptSeatSource.asObservable();
    payPalFinalizeSaleRequestAnnounced$ = this.payPalFinalizeSaleRequestAnnouncedSource.asObservable();
    checkoutOnlinePayPalSaleRequestAnnounced$ = this.checkoutOnlinePayPalSaleRequestAnnouncedSource.asObservable();
    bookingUpdatedAnnounced$ = this.bookingUpdatedAnnouncedSource.asObservable();
    preSaleUpgradeEventAnnounced$ = this.preSaleUpgradeEventAnnouncedSource.asObservable();
    saleUpgradeOfferEventAnnounced$ = this.saleUpgradeOfferEventAnnouncedSource.asObservable();
    saleUpgradeRequestAnnounced$ = this.saleUpgradeRequestAnnouncedSource.asObservable();
    voucherUpdatedAnnounced$ = this.voucherUpdatedAnnouncedSource.asObservable();
    extrasSelectedAnnounced$ = this.extrasSelectedAnnouncedSource.asObservable();
    cieloSaleRequestAnnounced$ = this.cieloSaleRequestAnnouncedSource.asObservable();
    
    
    paymeePixSaleRequestAnnounced$ = this.paymeePixSaleRequestAnnouncedSource.asObservable();
    
    makeFullSaleRequestAnnouced$ = this.makeFullSaleRequestAnnoucedSource.asObservable();


    logoffAnnouncedSource$ = this.logoffAnnouncedSource.asObservable();
    optionalInsuranceAnnouncedSource$ = this.optionalInsuranceAnnouncedSource.asObservable();

    announceTripSelected(requestEvent: TripSelectedEvent): void {
        this.tripSelectedAnnouncedSource.next(requestEvent);
    }

    announceOutletSelected(requestEvent: TripSelectedEvent): void {
        this.outletSelectedAnnouncedSource.next(requestEvent);
    }


    announceCheckoutOnlinePaymeePixSaleRequest(requestEvent: PaymeePixSaleRequest): void {
        this.checkoutOnlinePaymeePixSaleRequestAnnouncedSource.next(requestEvent);
    }

    announcePayPalSaleRequest(requestEvent: SaleRequestEvent): void {
        this.payPalSaleRequestAnnouncedSource.next(requestEvent);
    }

    announceCheckoutOnlinePayPalSaleRequest(requestEvent: SaleRequestEvent): void {
        this.checkoutOnlinePayPalSaleRequestAnnouncedSource.next(requestEvent);
    }

    announceMbWaySaleRequest(requestEvent: SaleRequestEvent): void {
        this.mbWaySaleRequestAnnouncedSource.next(requestEvent);
    }

    announceCheckoutOnlineMbWaySaleRequest(requestEvent: SaleRequestEvent): void {
        this.checkoutOnlineMbWaySaleRequestAnnouncedSource.next(requestEvent);
    }

    announcePayPalFinalizeSaleRequest(requestEvent: PayPalFinalizeSaleRequestModel): void {
        this.payPalFinalizeSaleRequestAnnouncedSource.next(requestEvent);
    }

    announceTripSearchDataChanged(): void {
        this.tripSearchDataChangedAnnouncedSource.next(undefined);
    }

    announceTripSelectionChanged(tripChange: TripChange): void {
        this.tripSelectionChangedAnnouncedSource.next(tripChange);
    }

    announceSeatsClearRequested(idTrip: number): void {
        this.clearTempSeatsAnnouncedSource.next(idTrip);
    }

    announceSeatCloseAllExceptRequested(idTrip: number): void {
        this.closeAllExceptSeatSource.next(idTrip);
    }

    announcePreSaleUpgradeEvent(idTrip: number): void {
        this.preSaleUpgradeEventAnnouncedSource.next(idTrip);
    }

    announceSaleUpgradeOfferEvent(event: SaleUpgradeOfferEvent): void {
        this.saleUpgradeOfferEventAnnouncedSource.next(event);
    }

    announceSaleUpgradeRequest(event: TripChange): void {
        this.saleUpgradeRequestAnnouncedSource.next(event);
    }

    announceBookingUpdate(): void {
        this.bookingUpdatedAnnouncedSource.next(undefined);
    }

    announceVoucherUpdate(): void {
        this.voucherUpdatedAnnouncedSource.next(undefined);
    }

    announceExtrasSelected(): void {
        this.extrasSelectedAnnouncedSource.next(undefined);
    }

    public announceCieloSaleRequest(request: CieloSaleRequest): void {
        this.cieloSaleRequestAnnouncedSource.next(request);
    }

    public announcePaymeePixSaleRequest(event: PaymeePixSaleRequest): void {
        this.paymeePixSaleRequestAnnouncedSource.next(event);
    }



    public announceLogoff(): void {
        this.logoffAnnouncedSource.next();
    }

    public announceOptionalInsuranceChange(include: boolean): void {
        this.optionalInsuranceAnnouncedSource.next(include);
    }

    public announceMakeFullSaleRequest<TPayment = {}>(requestEvent: MakeFullSaleRequestEvent<TPayment>): void {
        this.makeFullSaleRequestAnnoucedSource.next(requestEvent);
    }
}