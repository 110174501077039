import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApi, BaseService, EmptySerializer } from 'src/app/main';

@Injectable({
    providedIn: 'root'
})
export class MiscStockBookingService {

    private api: BaseApi;

    public constructor(
        base: BaseService
    ) {
        this.api =  base.setApi('schedule', 'MiscStockBooking');
    }

    public requestSaleMiscStockbySaleProcess(idDailyScheduleRoute: number, idMiscExtraType: number, quantity: number) : Observable<any> {
        const data = {
            IdDailyScheduleRoute: idDailyScheduleRoute,
            IdMiscExtraType: idMiscExtraType,
            Quantity: quantity
        };  

        return  this.api.post('RequestSaleMiscStockbySaleProcessNoAuth', data, new EmptySerializer())
    }

    public cancelMiscStockBooking(idMiscStockBooking: number) : Observable<any> {
        const data = {
            IdMiscStockBooking: idMiscStockBooking
        };

        return  this.api.post('CancelMiscStockBookingNoAuth', data, new EmptySerializer())
    }
}