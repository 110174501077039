import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApi, BaseService, OreonsCurrency, EmptySerializer } from 'src/app/main';

@Injectable({
    providedIn: 'root'
})
export class MiscExtraTypeService {

    private api: BaseApi;

    public constructor(
        base: BaseService
    ) {
        this.api =  base.setApi('sale', 'MiscExtraType');
    }

    public calculateExtraValue(idDailyScheduleRoute: number, idMiscExtraType: number, totalSaleValue: OreonsCurrency | null = null) : Observable<any> {
        const data = {
            IdDailyScheduleRoute: idDailyScheduleRoute,
            IdMiscExtraType: idMiscExtraType,
            TotalSaleValue: totalSaleValue
        };  

        return  this.api.post('CalculateExtraValueNoAuth', data, new EmptySerializer())
    }
}