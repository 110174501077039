import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
    BaseApi, ICompanyConfig, BaseService, TripSearch, OutletTripsSearch,
    OutletSearchSerializer, TripPrice, TripPriceSerializer, Trip
} from 'src/app/main';
import { GetTripsPassengerParams } from '../models/booking-process/passenger-param';

@Injectable({
    providedIn: 'root'
})
export class OutletService {

    private api: BaseApi;
    private config: ICompanyConfig;

    public constructor(
        base: BaseService
    ) {
        this.config = base.configService.company;
        this.api = base.setApi('gateway', 'sale', this.config.outletIdUser);
    }

    
    

    public getOutletTrips(
        departureLocationName: string,
        arrivalLocationName: string,
        isReturn: boolean,
        departureDate: Date,
        seats: number,
        returnDate?: Date
    ): Observable<OutletTripsSearch> {
        const data = {
            DepartureLocationName: departureLocationName,
            ArrivalLocationName: arrivalLocationName,
            IdPassengerType: this.config.normalPassengerType,
            DepartureDate: {
                Day: departureDate.getDate(),
                Hour: 0,
                Millisecond: 0,
                Minute: 0,
                Month: departureDate.getMonth() + 1,
                Second: 0,
                Year: departureDate.getFullYear()
            },
            SearchType: 2,
            IdBookingCouponRevalidation: null,
            IdContract: "-1",
            IdCurrency: 1,
            IdDepartureLocation: null,
            IdPointOfSale: 0,
            IdPromoCode: "-1",
            IdStaffTravelCategory: 0,
            IdStaffTravelType: 0,
            IsDeleted: false,
            IsDepartureDateOpen: null,
            IsOpen: false,
            IsReadOnly: false,
            IsReturn: isReturn,
            IsWithoutPrint: false,
            ReturnDate: {
                Day: returnDate !== null && returnDate !== undefined ? returnDate.getDate() : 0,
                Hour: 0,
                Millisecond: 0,
                Minute: 0,
                Month: returnDate !== null && returnDate !== undefined ? returnDate.getMonth() + 1 : 0,
                Second: 0,
                Year: returnDate !== null && returnDate !== undefined ? returnDate.getFullYear() : 0
            },
            SearchPage: {
                PageSize: -1,
                CurrentPage: 1
            },
            Seats: seats,
            ShowUnavailablePricingTrips: true
        };

        return this.api.post('GetOutletTripsNoAuth', data, new OutletSearchSerializer());
    }

    public getTripPrices(
        departureDate: Date,
        returnDate: Date,
        idArrivalLocation: number,
        IdDepartureLocation: number,
        idTrip: number,
        isReturn: boolean,
        hasPricing: boolean,
        tripType: number,
        isOpen: boolean = false,
        searchType: number,
        seats: number
    ): Observable<TripPrice[]> {
        const data = {
            BookingCouponToExchangeList: [],
            BookingCouponToRevalidateList: [],
            DepartureDate: isOpen ? null : {
                Day: departureDate.getDate(),
                Hour: 0,
                Millisecond: 0,
                Minute: 0,
                Month: departureDate.getMonth() + 1,
                Second: 0,
                Year: departureDate.getFullYear()
            },
            HasPricing: hasPricing,
            IdArrivalLocation: idArrivalLocation,
            IdContract: '-1',
            IdCurrency: 1,
            IdDailyScheduleRoute: '',
            IdDepartureLocation: IdDepartureLocation,
            IdPaymentBookingMiscCoupon: null,
            IdPromoCode: "-1",
            PromoCode: isOpen || this.config.offerOpen ? this.config.openPromoCode : "-1",
            IdStaffTravelType: 0,
            IsReturn: isReturn,
            ReturnDate: {
                Day: returnDate !== null && returnDate !== undefined ? returnDate.getDate() : 0,
                Hour: 0,
                Millisecond: 0,
                Minute: 0,
                Month: returnDate !== null && returnDate !== undefined ? returnDate.getMonth() + 1 : 0,
                Second: 0,
                Year: returnDate !== null && returnDate !== undefined ? returnDate.getFullYear() : 0
            },
            SearchType: searchType,
            TripKeys: [
                {
                    IdArrivalLocation: idArrivalLocation,
                    IdDepartureLocation: IdDepartureLocation,
                    idTrip: idTrip,
                    TripType: tripType
                }
            ],
            WithInsurance: true,
            IdPassengerType: this.config.normalPassengerType,
            Seats: seats
        };
        return this.api.postAsCollection('GetTripPricesNoAuth', data, new TripPriceSerializer(this.config));
    }
}
