import { Serializer } from "src/app/main";
import { SeatRequestConfirmation } from "../models";

export class SeatRequestConfirmationSerializer implements Serializer {
    fromJson(json: any) {
        return <SeatRequestConfirmation>{
            seatTransaction: json.SeatBooking.TransactionGuid,
            stockTransaction: json.StockBooking.TransactionGuid,
            price: json.Price,
            originalPrice: json.OriginalPrice,
            priceDetail: json.PriceDetail,
            idFareFamily: json.IdFareFamily,
            idPassengerType: json.IdPassengerType,
            idPassengerTypeConfiguration: json.IdPassengerTypeConfiguration
        };
    }

    fromJsonCollection(json: any): any[] {
        throw new Error("Method not implemented.");
    }
}