import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import moment from 'moment';
import {
    TripBanner, Customer, CompanyLogo, BookingSummary, Voucher, TripSearchData, Trip, BookingTicket, MiscStockBooking, ProcessStage,
    BookingProcessStage, BookingCoupon, RecentTrip, OutletTripRange
} from '../models';
import { StorageHelper } from '../helpers';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import PassengerType from '../models/passanger/passenger-type';
import GrantingAgencyGratuityRemarks from '../models/granting-agency/granting-agency-gratuity-remarks';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    readonly tripSearchData = 'tripSearchData';
    readonly trip = 'trip';
    readonly tripBack = 'tripBack';
    readonly passengers = 'passengers';
    readonly bookingTickets = 'bookingTickets';
    readonly backTripBookingTickets = 'backTripBookingTickets';
    readonly bookingProcessStage = 'bookingProcessStage';
    readonly bookingSummary = 'bookingSummary';
    readonly bookingSummaryCheckoutOnline = 'bookingSummaryCheckoutOnline';
    readonly currentTempTrip = 'currentTempTrip';
    readonly currentReturnTempTrip = 'currentReturnTempTrip';
    readonly tempSeats = 'tempSeats';
    readonly tempReturnSets = 'tempReturnSets';
    readonly recentTrips = 'recentTrips';
    readonly baseKey: string = 'logo';
    readonly tempSummaryKey: string = 'tempSummaryKey';
    readonly voucherKey: string = 'voucherKey';
    readonly idCustomer = "IdCustomer";
    readonly bookingSummaryRepurshase = "bookingSummaryRepurshase";
    readonly promoCodeDiscount = "promoCodeDiscount";
    readonly voucherCodeDiscount = "promoCodeDiscount";
    readonly promoCode = "promoCode";
    readonly loggedCustomer = 'loggedCustomer';
    readonly incompleteCustomer = 'incompleteCustomer';
    readonly voucherCode = "voucherCode";
    readonly miscStockBooking = 'miscStockBooking';
    readonly bannersCache = 'bannersCache';
    readonly tripBannersCache = 'tripBannersCache';
    readonly outletRedirect = 'outletRedirect';
    readonly returnOutletRedirect = 'returnOutletRedirect';
    readonly isExternalSale = 'isExternalSale';
    readonly forbiddenKeys: string[] = [this.recentTrips, 'OreonsUserContext', 'logo', 'cookiesAccepted', this.loggedCustomer, this.incompleteCustomer, this.bannersCache, this.tripBannersCache, AuthService.TOKEN_INFO_KEY];
    readonly idDailyScheduleRouteFromUpgradeTemp = "idDailyScheduleRouteFromUpgradeTemp";
    readonly lowestPriceFromUpgradeTemp = "lowestPriceFromUpgradeTemp";
    readonly saleFlowIdentifier = 'saleFlowIdentifier';
    readonly passengerTypes = 'passengerTypes';
    readonly grantingAgencyGratuityRemarks = 'grantingAgencyGratuityRemarks'

    constructor(
        private storageHelper: StorageHelper,
        @Inject(PLATFORM_ID) private platform: any
    ) { }

    setSiteTripBanner = (tripBanner: TripBanner): void => {
        tripBanner.ExpiresWhen = moment(new Date()).add(30, 'm').toDate();
        this.storageHelper.setItem(this.tripBannersCache + tripBanner.IdSiteTripBanner, tripBanner);
    }

    getSiteTripBanner = (idSiteTripBanner: number): TripBanner => {
        const tripBanner = this.storageHelper.getItem(this.tripBannersCache + idSiteTripBanner);
        if (!tripBanner) {
            return null;
        }

        if (moment(new Date()).isAfter(tripBanner.ExpiresWhen)) {
            return null;
        }

        return tripBanner;
    }

    setLoggedCustomer = (customer: Customer, router?: Router): void => {
        this.storageHelper.setItem(this.loggedCustomer, customer);
        if (!customer && router && environment.production) {
            let url = window.location.host;
            if (url.includes('ecommerce')) {
                url = window.location.origin.replace('https://ecommerce.', 'https://') + `?logout=1`;;
                window.location.href = url;
            }
        }
    }

    getLoggedCustomer = (): Customer => {
        return this.storageHelper.getItem(this.loggedCustomer);
    }

    setCompanyLogo = (id: number, logo: CompanyLogo): void => {
        this.storageHelper.setItem(this.baseKey + id.toString(), logo);
    }

    getCompanyLogo = (id: number): CompanyLogo => {
        return this.storageHelper.getItem(this.baseKey + id.toString());
    }

    setTempSummary = (summary: BookingSummary): void => {
        this.storageHelper.setItem(this.tempSummaryKey, summary);
    }

    getTempSummary = (): BookingSummary => {
        return this.storageHelper.getItem(this.tempSummaryKey);
    }

    setVoucherData = (voucher: Voucher): void => {
        this.storageHelper.setItem(this.voucherKey, voucher);
    }

    getVoucherData = (): Voucher => {
        return this.storageHelper.getItem(this.voucherKey);
    }

    setTripSearchData = (data: TripSearchData): void => {
        this.storageHelper.setItem(this.tripSearchData, data);
    }

    setSaleFlowIdentifier = (identifier: string): void => {
        this.storageHelper.setItem(this.saleFlowIdentifier, identifier);
    }

    getSaleFlowIdentifier = (): string => {
        return this.storageHelper.getItem(this.saleFlowIdentifier);
    }

    getTripSearchData = (): TripSearchData => {
        let result = this.storageHelper.getItem(this.tripSearchData);
        if (result === null || result === undefined) {
            return null;
        }


        result.departureDate = new Date(result.departureDate);
        if (result.returnDate !== undefined && result.returnDate !== null) {
            result.returnDate = new Date(result.returnDate);
        }
        return result;
    }

    setTrip = (trip: Trip): void => {
        this.storageHelper.setItem(this.trip, trip);
    }

    getTrip = (): Trip => {
        return this.storageHelper.getItem(this.trip, new Trip());
    }

    setBackTrip = (trip: Trip): void => {
        this.storageHelper.setItem(this.tripBack, trip);
    }

    getBackTrip = (): Trip => {
        return this.storageHelper.getItem(this.tripBack);
    }

    getBookingTickets = (): BookingTicket[] => {
        return this.storageHelper.getItem(this.bookingTickets);
    }

    setBookingTickets = (tickets: BookingTicket[]): void => {
        this.storageHelper.setItem(this.bookingTickets, tickets);
    }

    getMistStockBooking = (): MiscStockBooking[] => {
        return this.storageHelper.getItem(this.miscStockBooking);
    }

    setMiscStockBooking = (miscStockBooking: MiscStockBooking[]): void => {
        this.storageHelper.setItem(this.miscStockBooking, miscStockBooking);
    }

    getBackTripBookingTickets = (): BookingTicket[] => {
        return this.storageHelper.getItem(this.backTripBookingTickets);
    }

    setBackTripBookingTickets = (tickets: BookingTicket[]): void => {
        this.storageHelper.setItem(this.backTripBookingTickets, tickets);
    }

    public setInternalProcessStage(stage: ProcessStage): void {
        const processStage: BookingProcessStage = {
            stage
        };
        this.storageHelper.setItem(this.bookingProcessStage, processStage);
    }

    getInternalProcessStage = (): ProcessStage => {
        let stage = this.storageHelper.getItem(this.bookingProcessStage);
        return (stage !== null && stage !== undefined) ? stage.stage : null;
    }

    setBookingSummary = (summary: BookingSummary): void => {
        this.storageHelper.setItem(this.bookingSummary, summary);
    }

    getBookingSummary = (): BookingSummary => {
        return this.storageHelper.getItem(this.bookingSummary);
    }

    setCheckoutOnlineBookingSummary = (summary: BookingSummary): void => {
        this.storageHelper.setItem(this.bookingSummaryCheckoutOnline, summary);
    }

    getCheckoutOnlineBookingSummary = (): BookingSummary => {
        return this.storageHelper.getItem(this.bookingSummaryCheckoutOnline);
    }
    
    setPassengerTypes = (summary: PassengerType[]): void => {
        this.storageHelper.setItem(this.passengerTypes, summary);
    }

    getPassengerTypes = (): PassengerType[] => {
        return this.storageHelper.getItem(this.passengerTypes);
    }

    setCurrentTempTrip = (id: number, isReturnTrip: boolean): void => {
        if (isReturnTrip) {
            this.storageHelper.setItem(this.currentReturnTempTrip, id);
        } else {
            this.storageHelper.setItem(this.currentTempTrip, id);
        }
    }

    getCurrentTempTrip = (isReturnTrip: boolean): number => {
        return isReturnTrip
            ? this.storageHelper.getItem(this.currentReturnTempTrip)
            : this.storageHelper.getItem(this.currentTempTrip);
    }

    removeCurrentTempTrip = (isReturnTrip: boolean): void => {
        isReturnTrip
            ? this.storageHelper.removeItem(this.currentReturnTempTrip)
            : this.storageHelper.removeItem(this.currentTempTrip);
    }

    setCurrentTempTripSeats = (seats: BookingCoupon[], isReturnTrip: boolean, idDailyScheduleRoute: number): void => {
        if (isReturnTrip) {
            this.storageHelper.setItem(this.tempReturnSets + idDailyScheduleRoute.toString(), seats);
        } else {
            this.storageHelper.setItem(this.tempSeats + idDailyScheduleRoute.toString(), seats);
        }
    }

    removeCurrentTempTripSeats = (isReturnTrip: boolean, idDailyScheduleRoute: number): void => {
        if (isReturnTrip) {
            this.storageHelper.removeItem(this.tempReturnSets + idDailyScheduleRoute.toString());
        } else {
            this.storageHelper.removeItem(this.tempSeats + idDailyScheduleRoute.toString());
        }
    }

    getCurrentTempTripSeats = (idDailyScheduleRoute: number, isReturnTrip: boolean): BookingCoupon[] => {
        let tempSeatsJson: any;
        if (isReturnTrip) {
            tempSeatsJson = this.storageHelper.getItem(this.tempReturnSets + idDailyScheduleRoute.toString());
        } else {
            tempSeatsJson = this.storageHelper.getItem(this.tempSeats + idDailyScheduleRoute.toString());
        }

        const coupons: BookingCoupon[] = tempSeatsJson !== null ? tempSeatsJson : null;
        return coupons;
    }

    addRecentTrip = (trip: RecentTrip): void => {
        let trips: RecentTrip[] = this.storageHelper.getItem(this.recentTrips) || [];
        if (trips.some((element) => {
            return element.arrivalLocationCode === trip.arrivalLocationCode
                && element.departureLocationCode === trip.departureLocationCode
        })) {
            return;
        }

        if (trips.length === 3) {
            trips.pop();
        }

        trips.unshift(trip);
        this.storageHelper.setItem(this.recentTrips, trips);
    }

    removeRecentTrip = (trip: RecentTrip): void => {
        let trips: RecentTrip[] = this.storageHelper.getItem(this.recentTrips) || [];
        if (trips.length === 0) {
            return;
        }

        trips = trips.filter((element) => {
            return element.arrivalLocationCode !== trip.arrivalLocationCode
                || element.departureLocationCode !== trip.departureLocationCode
        });

        this.storageHelper.setItem(this.recentTrips, trips);
    }

    getRecentTrips = (): RecentTrip[] => {
        let recentTrips: RecentTrip[] = this.storageHelper.getItem(this.recentTrips) || [];
        return recentTrips.slice(-3);
    }

    getIdCustomer = (): number => {
        return this.storageHelper.getItem(this.idCustomer);
    }

    setIdCustomer = (idCustomer: number): void => {
        this.storageHelper.setItem(this.idCustomer, idCustomer);
    }

    getBookingSummaryRepurshase = (): BookingSummary => {
        return this.storageHelper.getItem(this.bookingSummaryRepurshase);
    }

    setBookingSummaryRepurshase = (bookingSummary: BookingSummary): void => {
        this.storageHelper.setItem(this.bookingSummaryRepurshase, bookingSummary);
    }

    getPromoCodeDiscount = (): number => {
        return this.storageHelper.getItem(this.promoCodeDiscount);
    }

    setPromoCodeDiscount = (discount: number): void => {
        this.storageHelper.setItem(this.promoCodeDiscount, discount);
    }

    getVoucherCodeDiscount = (): number => {
        return this.storageHelper.getItem(this.voucherCodeDiscount);
    }

    setVoucherCodeDiscount = (discount: number): void => {
        this.storageHelper.setItem(this.voucherCodeDiscount, discount);
    }

    getPromoCode = (): string => {
        return this.storageHelper.getItem(this.promoCode);
    }

    setPromoCode = (promoCode: string): void => {
        this.storageHelper.setItem(this.promoCode, promoCode);
    }

    getVoucherCode = (): string => {
        return this.storageHelper.getItem(this.voucherCode);
    }

    setVoucherCode = (voucherCode: string): void => {
        this.storageHelper.setItem(this.voucherCode, voucherCode);
    }

    setOutletRedirect = (redirect: OutletTripRange): void => {
        this.storageHelper.setItem(this.outletRedirect, redirect);
    }

    getOutletRedirect = (): OutletTripRange => {
        return this.storageHelper.getItem(this.outletRedirect);
    }

    setReturnOutletRedirect = (redirect: OutletTripRange): void => {
        this.storageHelper.setItem(this.returnOutletRedirect, redirect);
    }

    getReturnOutletRedirect = (): OutletTripRange => {
        return this.storageHelper.getItem(this.returnOutletRedirect);
    }

    getIdDailyScheduleRouteFromUpgradeTemp = (): any => {
        return this.storageHelper.getItem(this.idDailyScheduleRouteFromUpgradeTemp);
    }

    setIdDailyScheduleRouteFromUpgradeTemp = (idDailyScheduleRouteFromUpgradeTemp: any): void => {
        this.storageHelper.setItem(this.idDailyScheduleRouteFromUpgradeTemp, idDailyScheduleRouteFromUpgradeTemp);
    }

    removeIdDailyScheduleRouteFromUpgradeTemp = (): void => {
        this.storageHelper.removeItem(this.idDailyScheduleRouteFromUpgradeTemp);
    }

    getLowestPriceFromUpgradeTemp = (): any => {
        return this.storageHelper.getItem(this.lowestPriceFromUpgradeTemp);
    }

    setLowestPriceFromUpgradeTemp = (lowestPriceFromUpgradeTemp: any): void => {
        this.storageHelper.setItem(this.lowestPriceFromUpgradeTemp, lowestPriceFromUpgradeTemp);
    }

    removeLowestPriceFromUpgradeTemp = (): void => {
        this.storageHelper.removeItem(this.lowestPriceFromUpgradeTemp);
    }

    setIsExternalSale = (isExternalSale: Boolean): void => {
        this.storageHelper.setItem(this.isExternalSale, isExternalSale);
    }

    getIsExternalSale = (): boolean => {
        return this.storageHelper.getItem(this.isExternalSale);
    }    

    setGrantingAgencyGratuityRemarks = (item: GrantingAgencyGratuityRemarks[]): void => {
        this.storageHelper.setItem(this.grantingAgencyGratuityRemarks, item);
    }

    getGrantingAgencyGratuityRemarks = (): GrantingAgencyGratuityRemarks[] => {
        return this.storageHelper.getItem(this.grantingAgencyGratuityRemarks);
    }


    clear = (optinalForbiddenKeys: string[] = []): void => {
        if (isPlatformServer(this.platform)) {
            return;
        }

        for (let key in localStorage) {
            let keyAsString = key.toString();
            if (this.forbiddenKeys.some(c => keyAsString.includes(c))) {
                continue;
            }

            if (optinalForbiddenKeys && optinalForbiddenKeys.some(c => keyAsString.includes(c))) {
                continue;
            }

            this.storageHelper.removeItem(key);
        }
    }

    clearBannerStorage = (ids: number[]) => {
        if (isPlatformServer(this.platform)) {
            return;
        }

        let keys = new Array<string>();
        for (let id of ids) {
            keys.push(this.bannersCache + id);
        }
        this.clear(keys);
    }

    clearTripBannerStorage = (ids: number[]) => {
        if (isPlatformServer(this.platform)) {
            return;
        }

        let keys = new Array<string>();
        for (let id of ids) {
            keys.push(this.tripBannersCache + id);
        }
        this.clear(keys);
    }
}
