import { Serializer } from "src/app/main";
import { SeatMapCell } from "../models";

export class SeatMapCellSerializer implements Serializer {
    fromJson(json: any) {
        throw new Error("Method not implemented.");
    }

    fromJsonCollection(json: any): any[] {
        const result: SeatMapCell[] = []

        for (const seatMapCell of json) {
            const item: SeatMapCell = {
                status: seatMapCell.Status,
                idSeatMapCell: seatMapCell.IdSeatMapCell
            };
            result.push(item);
        }

        return result;
    }


}