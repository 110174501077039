import { Injectable } from "@angular/core";
import { BaseApi, BaseService, EmptySerializer } from "src/app/main";
import { FormOfPayment } from "../models";

@Injectable({
    providedIn: 'root'
})
export class FormOfPaymentService {

    private api: BaseApi;

    public constructor(
        base: BaseService
    ) {
        this.api = base.setApi('gateway', 'sale/payment');
    }

    public getAvailableFormOfPaymentsBySchedule(idDailyScheduleRouteList: number[], idPartnerDailyScheduleRouteList: number[]): Promise<number[]> {
        const data = {
            idDailyScheduleRouteList: idDailyScheduleRouteList,
            idPartnerDailyScheduleRouteList: idPartnerDailyScheduleRouteList
        };

        return this.api.post('available', data).toPromise();
    }
}