import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ICompanyConfig, IConfig, Site } from "../models";
import AuthResponse from '../models/config/AuthResponse';
import { ConfigService } from './config.service';

@Injectable()
export class AuthService {

    public constructor(
        private config: ConfigService
    ) {
    }

    public async login(): Promise<AuthResponse> {        
        try {            
            const details = {
                'username': this.config.company.userAuthBearer,
                'password': this.config.company.passwordAuthBearer,
                'grant_type': 'password',
                'ProductId': '487A774C-0485-401F-84D2-61ED508BECC3'
            };

            let formBody = [];
            for (let property in details) {
                let encodedKey = encodeURIComponent(property);
                let encodedValue = encodeURIComponent(details[property]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
            var body = formBody.join("&");

            const ret = await fetch(this.config.config.gatewayApiUrl + "/OAuth", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: body
            });

            var res: AuthResponse = await ret.json();
            var now = new Date();            
            res.expirationTime = new Date(now.getTime() + res.expires_in * 1000);

            return res;
        } catch (e) {
            console.log(e);
        }
    }

    public IsAMajorThenB(a: Date, b: Date): boolean {
        if (a == null || b == null) {
            return false;
        }
        if (a.getUTCFullYear() != b.getUTCFullYear()) {
            return a.getUTCFullYear() > b.getUTCFullYear();
        }
        if (a.getUTCMonth() != b.getUTCMonth()) {
            return a.getUTCMonth() > b.getUTCMonth();
        }
        if (a.getUTCDate() != b.getUTCDate()) {
            return a.getUTCDate() > b.getUTCDate();
        }
        if (a.getUTCHours() != b.getUTCHours()) {
            return a.getUTCHours() > b.getUTCHours();
        }
        if (a.getUTCMinutes() != b.getUTCMinutes()) {
            return a.getUTCMinutes() > b.getUTCMinutes();
        }
        if (a.getUTCSeconds() != b.getUTCSeconds()) {
            return a.getUTCSeconds() > b.getUTCSeconds();
        }
        return false;
    }

    private static _isAuthing = false;
    public static TOKEN_INFO_KEY = "AuthService_TOKEN_INFO_KEY";
    public getToken(renewToken = false): Promise<string> {
        return new Promise<string>(async (acc) => {            
            var _tokenInfo: AuthResponse = null;
            var rawToken = localStorage.getItem(AuthService.TOKEN_INFO_KEY);
            if (rawToken) {
                try {
                    _tokenInfo = JSON.parse(rawToken);
                } catch (error) {
                }
            }

            if(_tokenInfo && this.config.company.userAuthBearer?.trim() != _tokenInfo.userName?.trim())
                renewToken = true;

            if (renewToken || !_tokenInfo || this.IsAMajorThenB(new Date(), new Date(_tokenInfo.expirationTime))) {
                _tokenInfo = await this.login();
                localStorage.setItem(AuthService.TOKEN_INFO_KEY, JSON.stringify(_tokenInfo));
            }
            acc(_tokenInfo?.access_token ?? "");
        });
    }



    public clearToken() {
        localStorage.removeItem(AuthService.TOKEN_INFO_KEY);
    }

}


function wait(time: number): Promise<void> {
    return new Promise<void>((acc) => {
        setTimeout(() => acc(), time);
    });
}