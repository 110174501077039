import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { BaseApi, BaseService, EmptySerializer } from "src/app/main";
import { PassengerTypeConfiguration } from "../models/booking-process/passenger-type-configuration";

@Injectable({
    providedIn: 'root'
})
export class PassengerConfigurationService {

    private api: BaseApi;

    public constructor(
        base: BaseService
    ) {
        this.api = base.setApi('gateway', 'core/PassengerTypeConfiguration');
    }

    public getById(idPassengerTypeConfiguration:number): Promise<PassengerTypeConfiguration> {
        const data = {
            id : idPassengerTypeConfiguration
        };
        return lastValueFrom(this.api.post('GetById', data));
    }
}