import { SeatPrice, Serializer } from "src/app/main";

export class SeatPriceSerializer implements Serializer {
    fromJson(json: any): any {
    }

    fromJsonCollection(json: any): any[] {
        const result: SeatPrice[] = [];

        json.forEach(element => {
            const item: SeatPrice = Object.assign({}, element);
            result.push(item);
        });

        return result;
    }
}